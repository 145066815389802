body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body {
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo-wrapper {
  width: 340px;
  height: 100px;
  padding: 15px;
  border-radius: 10px;
  perspective: 1000px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

h2 {
  text-align: center;
}

/* For holding the front-side and back-side elements */
.logo-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1s;
  transform-style: preserve-3d;
}

/* For adding the Horizontal Flip Effect */

.logo-wrapper .logo-inner {
  animation: animate 10s linear infinite;
}

/* For positioning the front-side and back-side elements */
.front,
.back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* For styling the front-side element  */
.front {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* For styling the back-side element  */
.back {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotateY(180deg);
}

.header-nav .nav {
  padding-top: 15px;
}

.extra-nav .extra-cell {
  display: none;
}

.margin-right-left-10px{
  margin-right: 5px;
  margin-left: 5px;
  
}
@media only screen and (max-width: 767px) {
  .header-style-1 .top-bar .row {
      display: none;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-toggle {
      margin-top: 26px;
  }
}

.logo-wrapper {
  margin-left: -80px;
}



@keyframes animate { 
  0% { 
      transform: rotateY(0deg); 
  } 

  50% { 
    transform: rotateY(180deg); 
  }
  100% { 
      transform: rotateY(360deg); 
  } 
}

.about-us.mt-separator {
	margin-bottom: 0px!important;
}
